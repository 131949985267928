<template>
	<AddEditForm :headerTitle="$t('pages.cms.editPage')" :backTitle="$t('menu.backToCmsPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :onDelete="onDelete">
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="editingPage != null">
				<template v-slot:body>
					<CForm>
						<CInput :label="$t('common.name')" :placeholder="$t('pages.product.enterCategoryName')"
								v-model="editingPage.systemName" required horizontal readonly>
						</CInput>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">
								{{ $t('common.description') }}
							</label>
							<div class="col-sm-9 input-group ckeditor-wrapper">
								<ckeditor v-model="editingPage.body" :config="editorConfig"></ckeditor>
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>

			<Accordion :headerTitle="$t('common.displayInfo')" v-if="editingPage != null">
				<template v-slot:body>
					<CForm>
						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.published') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingPage.id"
										 color="info"
										 :checked.sync="editingPage.published"
										 size="sm" />
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.includeInSitemap') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingPage.id"
										 color="info"
										 :checked.sync="editingPage.includeInSitemap"
										 size="sm" />
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.includeInMenu') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="editingPage.id"
										 color="info"
										 :checked.sync="editingPage.includeInMenu"
										 size="sm" />
							</div>
						</div>

					</CForm>
				</template>
			</Accordion>

			<Confirmation ref="confirmation"></Confirmation>
		</template>

	</AddEditForm>
</template>

<script>
	import i18n from '@/plugins/i18n'
	import { mapGetters, mapState } from 'vuex'
	import Accordion from '@/components/Accordion.vue'
	import AddEditForm from '@/components/AddEditForm.vue'
	import Confirmation from '@/components/Confirmation.vue'
    import { globalConfigs } from '@/global/index'
	export default {
		name: 'cmsPageEdit',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				invalidInput: false,
				errorInput: '',
                editorConfig: { ...globalConfigs.CKEditorConfig, fullPage: true },
			}
		},
		components: { AddEditForm, Accordion, Confirmation},
		computed: {
            ...mapState('cms', ['editingPage', 'isLoadingPageDetail']),
		},
		created() {
            console.log('----------------------------CREATED-------------------------------');
		},
		methods: {
			onGoBack() {
				this.$router.push('/cms/pages/list');
			},
			onSave() {
				this.updatePage(false);
			},
			onSaveContinue() {
				this.updatePage(true);
			},
			async onDelete() {
				if (this.editingPage && this.editingPage.id > 0) {

					this.$refs.confirmation.show(i18n.t('pages.cms.confirmDeletePage'), async () => {
						var retVal = await this.$store.dispatch("cms/deletePage", this.editingPage.id);
						if (retVal == true) {
							this.onGoBack();
						}
					});
				}

			},
			async updatePage(bContinueEdit) {
				var retVal = await this.$store.dispatch("cms/savePage");
				if (retVal == true && !bContinueEdit) {
					this.$router.push('/cms/pages/list');
				}
			},
			onCancel() {
                this.$router.push('/cms/pages/list');
			},
		},
		mounted() {
            console.log('-----------------------------------------------------------', this.$route.params.id);
			this.$store.dispatch("cms/editPage", this.$route.params.id);
		},
	}
</script>
<style scoped>
	.ckeditor-wrapper > div {
		flex: 0 0 100%;
		max-width: 100%;
	}
</style>